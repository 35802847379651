import { useTheme } from "@emotion/react";
import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { removeBackendResultList } from "redux/features/backendResult/backendResultSlice";
import { deleteBackendResult } from "server/backendResult/backendUpdate";

const DeleteBackendResultModal = memo(
  ({
    deleteBackendResultModalOpen,
    setDeleteBackendResultModalOpen,
    currentBackendResult,
  }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    const handleDeleteBackendResult = useCallback(async () => {
      setLoading(true);
      try {
        const result = await deleteBackendResult(currentBackendResult?._id);
        if (result?.data?.success) {
          dispatch(removeBackendResultList(currentBackendResult?._id));
          enqueueSnackbar(
            result?.data?.message || "Backend result deleted successfully",
            { variant: "success" }
          );
        } else {
          enqueueSnackbar(
            result?.data?.message || "Failed to delete backend result",
            { variant: "error" }
          );
        }
      } catch (error) {
        enqueueSnackbar("An error occurred while deleting the backend result", {
          variant: "error",
        });
      } finally {
        setLoading(false);
        setDeleteBackendResultModalOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBackendResult?._id]);

    return (
      <Modal
        open={deleteBackendResultModalOpen}
        onClose={() => setDeleteBackendResultModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            backgroundColor: theme.palette.background.main,
            borderRadius: "5px",
            padding: "25px",
            outline: "none",
          }}
        >
          <Grid
            container
            rowSpacing={3}
            columnSpacing={3}
            alignItems={"center"}
          >
            <Grid item xs={6}>
              <Typography
                sx={{ color: theme.palette.text.white }}
                className="!capitalize"
              >
                Delete Backend Result List
              </Typography>
            </Grid>
            <Grid item xs={6} className="text-end">
              <IconButton
                onClick={() => setDeleteBackendResultModalOpen(false)}
                title="Close"
              >
                <HighlightOff />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Box className="space-y-8">
                <Typography
                  variant="h6"
                  style={{
                    color: theme.palette.text.white,
                    textAlign: "center",
                  }}
                >
                  Are you sure to delete this backend result?
                  <br />
                  <span className="!text-base">
                    Version : {currentBackendResult?.version}
                  </span>
                  <br />
                </Typography>
                <Box className="flex gap-2 justify-between">
                  <Button
                    onClick={() => setDeleteBackendResultModalOpen(false)}
                    sx={{
                      background: "red",
                      ":hover": {
                        background: "red",
                      },
                      color: "white",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      width: "100px",
                    }}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleDeleteBackendResult}
                    sx={{
                      background:
                        "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                      boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                      color: "white",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      width: "100px",
                      pointerEvents: loading ? "none" : "auto",
                    }}
                    variant="contained"
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Delete"
                    )}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    );
  }
);

export default DeleteBackendResultModal;
