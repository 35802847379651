import { useTheme } from "@emotion/react";
import { CloudUpload, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { memo, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBackendUpdateList } from "redux/features/backendUpdate/backendUpdateSlice";
import { createBackendUpdate } from "server/backendUpdate/backendUpdate";
import * as yup from "yup";

const validationSchema = yup.object({
  version: yup.string("Enter your version").required("Version is required"),
  version_code: yup
    .string("Enter your version code")
    .required("Version code is required"),
  file: yup.mixed().required("File is required"),
  broker_id: yup.array().min(1, "At least one broker is required"),
  type: yup.string("Enter your type").required("Type is required"),
});

const CreateBackendUpdateModal = memo(
  ({ createBackendUpdateModalOpen, setCreateBackendUpdateModalOpen }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const brokerList = useSelector((state) => state.broker.brokerList);
    const brokerListAdmin = useMemo(
      () =>
        brokerList?.filter((broker) => broker?.broker_type === "admin") || [],
      [brokerList]
    );

    const formik = useFormik({
      initialValues: {
        version_code: "",
        version: "",
        file: null,
        broker_id: [],
        type: "",
      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        setLoading(true);
        try {
          const formData = new FormData();
          formData.append("version", values.version);
          formData.append("version_code", values.version_code);
          formData.append("file", values.file);
          formData.append("broker_id", JSON.stringify(values.broker_id));
          formData.append("type", values.type);

          const result = await createBackendUpdate(formData);
          if (result?.data?.success) {
            dispatch(addBackendUpdateList(result?.data?.data));
            enqueueSnackbar(result?.data?.message, { variant: "success" });
            setCreateBackendUpdateModalOpen(false);
            formik.resetForm();
          } else {
            enqueueSnackbar(result?.data?.message, { variant: "error" });
          }
        } catch (error) {
          enqueueSnackbar("An error occurred while creating backend update!", {
            variant: "error",
          });
        } finally {
          setLoading(false);
        }
      },
    });

    const handleFileChange = (event) => {
      const file = event.currentTarget.files[0];
      formik.setFieldValue("file", file);
    };

    const handleBrokerChange = (brokerId) => {
      const { broker_id } = formik.values;
      const newBrokerIds = broker_id.includes(brokerId)
        ? broker_id.filter((id) => id !== brokerId) // Remove if already selected
        : [...broker_id, brokerId]; // Add if not selected
      formik.setFieldValue("broker_id", newBrokerIds);
    };

    const handleSelectAll = () => {
      if (formik.values.broker_id.length === brokerListAdmin.length) {
        // If all are selected, deselect all
        formik.setFieldValue("broker_id", []);
      } else {
        // Otherwise, select all
        const allBrokerIds = brokerListAdmin.map((broker) => broker.broker_id);
        formik.setFieldValue("broker_id", allBrokerIds);
      }
    };

    return (
      <Modal
        open={createBackendUpdateModalOpen}
        onClose={() => setCreateBackendUpdateModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            backgroundColor: theme.palette.background.main,
            borderRadius: "5px",
            padding: "25px",
            outline: "none",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              rowSpacing={4}
              columnSpacing={3}
              alignItems={"center"}
            >
              <Grid item xs={6}>
                <Typography
                  sx={{ color: theme.palette.text.white, fontWeight: 500 }}
                  className="!capitalize"
                >
                  Backend Update Create
                </Typography>
              </Grid>
              <Grid item xs={6} className="text-end">
                <IconButton
                  onClick={() => {
                    setCreateBackendUpdateModalOpen(false);
                    formik.resetForm();
                  }}
                  title="Close"
                >
                  <HighlightOff />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Box className="space-y-6">
                  <FormControl
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid var(--primary-color)",
                        },
                        "&:hover fieldset": {
                          border: "1px solid var(--secondary-color)",
                        },
                        "&.Mui-focused fieldset": {
                          border: `1px solid var(--secondary-color)`,
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: theme.palette.text.white,
                        "&.Mui-focused": {
                          color: theme.palette.text.white,
                        },
                      },
                    }}
                  >
                    <TextField
                      label="Version"
                      type="text"
                      id="version"
                      name="version"
                      size="small"
                      placeholder="Your version 1"
                      value={formik.values.version}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.version && Boolean(formik.errors.version)
                      }
                      helperText={
                        formik.touched.version && formik.errors.version
                      }
                    />
                  </FormControl>

                  <FormControl
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid var(--primary-color)",
                        },
                        "&:hover fieldset": {
                          border: "1px solid var(--secondary-color)",
                        },
                        "&.Mui-focused fieldset": {
                          border: `1px solid var(--secondary-color)`,
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: theme.palette.text.white,
                        "&.Mui-focused": {
                          color: theme.palette.text.white,
                        },
                      },
                    }}
                    size="small"
                  >
                    <TextField
                      label="Version code"
                      type="text"
                      id="version_code"
                      name="version_code"
                      size="small"
                      placeholder="Your version code 0.0.1"
                      value={formik.values.version_code}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.version_code &&
                        Boolean(formik.errors.version_code)
                      }
                      helperText={
                        formik.touched.version_code &&
                        formik.errors.version_code
                      }
                    />
                  </FormControl>

                  <FormControl
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid var(--primary-color)",
                        },
                        "&:hover fieldset": {
                          border: "1px solid var(--secondary-color)",
                        },
                        "&.Mui-focused fieldset": {
                          border: `1px solid var(--secondary-color)`,
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: theme.palette.text.white,
                        "&.Mui-focused": {
                          color: theme.palette.text.white,
                        },
                      },
                    }}
                    size="small"
                  >
                    <InputLabel id="broker_id">Broker</InputLabel>
                    <Select
                      labelId="broker_id"
                      name="broker_id"
                      id="broker_id"
                      label="Broker"
                      value={formik.values.broker_id}
                      multiple
                      renderValue={(selected) =>
                        selected
                          .map(
                            (id) =>
                              brokerListAdmin.find(
                                (broker) => broker.broker_id === id
                              )?.name
                          )
                          .join(", ")
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            background: theme.palette.background.main,
                          },
                        },
                      }}
                    >
                      <MenuItem onClick={handleSelectAll}>
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": {
                              color: "var(--active-color)",
                            },
                          }}
                          checked={
                            formik.values.broker_id.length ===
                            brokerListAdmin.length
                          }
                        />
                        <ListItemText primary="Select all broker" />
                      </MenuItem>
                      {brokerListAdmin?.map((broker) => (
                        <MenuItem
                          key={broker.broker_id}
                          onClick={() => handleBrokerChange(broker.broker_id)}
                        >
                          <Checkbox
                            sx={{
                              "& .MuiSvgIcon-root": {
                                color: "var(--active-color)",
                              },
                            }}
                            checked={formik.values.broker_id.includes(
                              broker.broker_id
                            )}
                          />
                          <ListItemText primary={broker.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.broker_id && formik.errors.broker_id && (
                      <Typography className="!text-[#f44336] !text-[12px] !mt-1">
                        {formik.touched.broker_id && formik.errors.broker_id}
                      </Typography>
                    )}
                  </FormControl>

                  <FormControl
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid var(--primary-color)",
                        },
                        "&:hover fieldset": {
                          border: "1px solid var(--secondary-color)",
                        },
                        "&.Mui-focused fieldset": {
                          border: `1px solid var(--secondary-color)`,
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: theme.palette.text.white,
                        "&.Mui-focused": {
                          color: theme.palette.text.white,
                        },
                      },
                    }}
                    size="small"
                  >
                    <InputLabel id="type">Type</InputLabel>
                    <Select
                      MenuProps={{
                        PaperProps: {
                          style: {
                            background: theme.palette.background.main,
                          },
                        },
                      }}
                      labelId="type"
                      id="type"
                      label="Type"
                      name="type"
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.type && Boolean(formik.errors.type)}
                    >
                      <MenuItem value="" disabled key={"first"}>
                        Select type
                      </MenuItem>
                      <MenuItem value={"AUTOMATIC"}>Automatic</MenuItem>
                      <MenuItem value={"NEED_PERMISSION"}>
                        Need Permission
                      </MenuItem>
                    </Select>
                    {formik.touched.type && formik.errors.type && (
                      <Typography className="!text-[#f44336] !text-[12px] !mt-1">
                        {formik.touched.type && formik.errors.type}
                      </Typography>
                    )}
                  </FormControl>

                  <Box
                    sx={{
                      border:
                        formik.touched.file && formik.errors.file
                          ? "1px dashed #f44336"
                          : "1px dashed var(--primary-color)",
                      borderRadius: "10px",
                      width: "100%",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <label
                      htmlFor="file"
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <CloudUpload
                        sx={{ fontSize: "40px", color: "var(--icon-color)" }}
                      />
                      {formik.values.file ? (
                        formik.values.file.name
                      ) : (
                        <span>Upload File</span>
                      )}
                    </label>
                    <input
                      id="file"
                      type="file"
                      hidden
                      onChange={handleFileChange}
                    />
                  </Box>
                  {formik.touched.file && formik.errors.file && (
                    <Typography className="!text-[#f44336] !text-[12px] !mt-1 !ml-5">
                      {formik.touched.file && formik.errors.file}
                    </Typography>
                  )}

                  <Box className="flex justify-end">
                    <Button
                      sx={{
                        background:
                          "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                        boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                        color: "white",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: "#6553D8",
                        },
                      }}
                      disabled={loading}
                      type="submit"
                      size="medium"
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                      ) : (
                        "Create"
                      )}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    );
  }
);

export default CreateBackendUpdateModal;
